.uci__cardblock {
  border: 3px solid #f3f3f3;
  border-radius: 6px;
  padding: 25px 25px 25px 25px;
  width: 100%;
  margin-bottom: 20px;
}

.uci__cardblock h3 {
  margin:0 0 10px 0;
  padding:0;
}

.uci__cardblock>div:first-of-type {
  display: flex;
  align-items: center;
}

.uci__cardblock button {
  box-shadow: none !important;
}
