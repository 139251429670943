.tenant-site-selector .list tr {
  border-bottom: 1px solid #DDDDDD;
}

.tenant-site-selector .list tr td{
  padding: 0 4px !important;
}

.tenant-site-selector .list tr td:first-of-type{
  width: 10px;
}

.tenant-site-selector .list th{
  font-family: "Open Sans SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #292F41;
  border-top: 1px solid #DDDDDD;
  padding: 4px 15px;
}

.select__button:not(:hover) {
  font-family: "Open Sans Regular";
  font-weight: normal;
  color: #42526E;
}

.tenant-site-selector .list tr td svg{
  stroke: #8B97AD;
}

.select__button:hover, .select__button__active {
  box-shadow: none !important;
  color: #42526E !important;
}
