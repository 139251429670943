.menu__dropdown__lenguage {
  background-color: #ffffff !important;
  border-radius: 30px !important;
  justify-content: center !important;
  padding: 0 !important;
}

.header {
  align-items: center;
  width: 100%;
  height: 100%;
}

.header-container{
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
}



.header__brand {
  margin-top: 12px;
  width: 100%;
  max-width: 146px;
}

.header__logo {
  display: flex;
  align-items: center;
}
.header__options{
  display: flex;
  margin-top: 1rem;
}

.header__option__menu{
  margin-right: 10px !important;
  margin-top:  1rem;
  
}

.tenant_selector{
  margin-right: 10px !important;
  margin-top:  1rem;
  height: 40px;
}

.header__notification {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  cursor: pointer;
}

.header__avatar {
  cursor: pointer;
  padding: 4px 6px;
}

.header .uci__icon {
  cursor: pointer;
  padding: 8px 10px;
}

.header .uci__badge {
  margin: 10px 30px;
}

.header__avatar:hover, .header__menu .uci__icon:hover, .header__notification .uci__icon:hover {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 10px;
}

.header__menu .uci__input--disabled{
  width: 90%;
}

.header__menu-controller {
  display: none;
}

.user-section>*:first-child {
  padding: 8px;
}

.user-section>*:first-child>strong {
  display: block;
}

.header-user-menu{
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #8B97AD;
}

.header__user__dialog {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.header__user__dialog div {
  padding: 4px 12px 8px 4px;
  text-align: left;
}

.header__user__dialog p:first-of-type {
  padding-left: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #8B97AD;
}

.header__user__dialog p:last-of-type {
  padding-left: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #42526E;
}

.header__user__dialog .profile {
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #383838;
}

.header__user__dialog .logout {
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #8B97AD;
}

.header__user__dialog hr{
  height: 1px;
}

.header__environment__identifier{
  flex: none;
  height: 10%;
  margin-left: 16px;
  @media (min-width: 760px) and (max-width: 1200px) {
    margin: 10px 20px;
    width: 40%;
    font-size: 10px;
    margin-right: 13px;
    padding-right: 0;
  }
}

.header__menu__link_logo{
  flex: none;
}
