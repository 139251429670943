.app__table {
  border-collapse: collapse;
  width: 100%;
  margin: 16px 0 40px 0;
}

.app__table thead {
  background: #FFFFFF;
  border-radius: 6px;
}

.app__table th {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #292F41;
  text-align: left;
  padding: 12px 20px;
  max-width: 120px;
}

.app__table>tbody>tr{
  background: rgba(246, 246, 246, 0.2);
  border-bottom: 8px solid rgba(255,255,255);
  border-radius: 6px;
}

.app__table>tbody>tr>td {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 20px;
  color: #292F41;
  max-width: 250px;
  word-break: break-word;
}
.app__table input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 0;
  box-sizing: border-box;
  width:16px;
  height:16px;
  border-radius: 4px;
  cursor:pointer;
  display: grid;
  place-content: center;
}

.app__table input[type="checkbox"]:hover {
  border: 1px solid #7569F4;
}

.app__table input[type="checkbox"]:checked {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #7569F4;
  box-sizing: border-box;
  background: #7569F4;
}
.app__table input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #ffffff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.app__table input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.app__table--empty>tbody>tr>td {
  background: rgba(234, 230, 255, 0.2);
  border-radius: 6px;
  text-align: center;
  padding: 170px 15px;
}

.app__table>tbody>tr:hover>td {
  color: #7569F4 !important;
  font-weight: bold;
}

.app__table--empty>tbody>tr:hover>td {
  color: #292F41;
}

.app__table--empty-message{
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  color: #292F41;
}

.app__table__export {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(233, 233, 233, 0.2);
}

.app__table__export--selected {
  float:left;
  height: 100%;
  font-size:16px;
  margin: 8px 20px 0 0;
  vertical-align: sub;
}
.app__table__export-button {
  display: flex;
  column-gap: 20px;
}
.export-button,
.export-button:hover {
  background-color: #EAECFE !important;
  border: 2px solid #7569F4 !important;
  cursor: pointer;
  color: #7569F4 !important;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.export-button:disabled {
  cursor: not-allowed;
}


.app__table button {
  cursor: pointer;
  outline: none !important;
  border: none !important;
}

.app__table>tbody>tr>td .uci__button:focus {
  box-shadow: 0 0 0 0 !important;
}
