.filter__badge{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  color: #4A1FEB;
  background-color: #EAE6FF;
  margin-right: 5px;
  min-width: 160px;
  padding: 5px 10px;
}

.filter__badge span{
  font-size: 13px;
  font-weight: 400;
}

.filter__badge .close__badge {
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--c-brand--menu-font);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  line-height: 18px;
  padding: 0;
  border: solid 1px var(--c-brand--menu-font);
  position: relative;
  top: 50%;
  margin-top: -9px;
  margin-left: 8px;
}

.filter__badge .close__badge svg {
  position: relative;
  top: -4px;
  left: -4px;
}
