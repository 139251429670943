.error{
  margin: 30px 0;
}

.error p {
  font-size: 14px;
  line-height: 20px;
  color: #42526E;
  margin: 0 !important;
}
