.tenant-site-selector .uci__tab .uci__tab__header {
  border-bottom: none;
}

.tenant-site-selector .uci__button i{
  color: #7569F4;
}

.tenant-site-selector .uci__tab .uci__tab__header button {
  border: none;
  background: transparent;
  color: #8B97AD;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
}

.tenant-site-selector .uci__tab .uci__tab__header button.uci__tab__header__button--active {
  border-bottom: 2px solid #7569F4;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292F41;
}

.tenant-site-selector .grid{
  margin: 0 0 30px 0;
  text-align: center;
  padding: 0 10px;
}

.tenant-site-selector .grid_content{
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  max-height: 200px;
}

.tenant-site-selector .grid_content::-webkit-scrollbar {
  width: 10px;
}

.tenant-site-selector .grid_content::-webkit-scrollbar-track {
  background-color: #DDDDDD;
  border-radius: 100px;
}

.tenant-site-selector .grid_content::-webkit-scrollbar-thumb {
  background-color: #7569F4;
  border-radius: 100px;
}

.tenant-site-selector .grid__item {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.tenant-site-selector .grid__item>p {
  line-height: 28px;
  color: #292F41;
}

.tenant-site-selector .error__icon__sites{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.tenant-site-selector .error__icon__tenant{
  display: flex;
  justify-content: center;
}

.tenant-site-selector .uci__input div.uci__input__input-wrapper{
  border: 2px solid #8B97AD;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 14px;
}

.tenant-site-selector .uci__input:hover:not(.uci__input--disabled) .uci__input__input-wrapper,
.tenant-site-selector .uci__input--focus.uci__input--enabled .uci__input__input-wrapper{
  border: 2px solid #7569f4 !important;
  background-color: #ffffff;
}

.tenant-site-selector .uci__input i>svg {
  width: 16px;
}

.tenant-site-selector .uci__input i {
  margin: 24px 5px 10px;
}

.tenant-site-selector .uci__button-action-group .uci__button-group .uci__button--primary{
  background-color: #7569F4;
  border-color: #7569F4;
}
