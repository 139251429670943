.filter .uci__dropdown > button {
  background: #ffffff;
  border: 2px solid #dddddd;
  border-radius: 4px;
  line-height: 38px;
  color: #42526E;
  height: 44px;
}

.filter.adapted .uci__dropdown .uci__input div.uci__input__input-wrapper {
  background-color: #fafbfc;
  border-color: #dfe1e6;
}

.filter.adapted .uci__dropdown i>svg {
  margin: 2px 0 2px 4px;
  max-height: 20px;
}

.filter.adapted .uci__dropdown .uci__input input {
  min-height: 40px;
}

.filter.adapted .uci__dropdown .uci__input input::placeholder {
  color: #42526E;
  line-height: 28px;
}

.filter .uci__dropdown > button span{
  font-size: 14px;
  padding-left: 3px;
}

.filter .uci__dropdown ul>li *{
  padding-left: 20px;
}

.filter .filter__label{
  color: #42526E;
  font-size: 11px;
  position: absolute;
  top: 3px;
  left: 28px;
  z-index: 10;
  pointer-events: none;
}

.filter .uci__dropdown.uci__dropdown--open>button,
.filter .uci__dropdown.uci__dropdown--open>button:not(:disabled),
.filter .uci__dropdown>button:active:not(:disabled),
.filter .uci__dropdown>button:focus:not(:disabled),
.filter .uci__dropdown>button:not(:disabled):hover{
  background-color: #ffffff;
  border-color: var(--c-primary-new);
}

.filter .uci__dropdown ul>li.uci__dropdown__item--active>button,
.filter .uci__dropdown>ul>li>button:hover{
  background-color: var(--c-background-bordered);
  color: var(--c-primary-new);
}

.filter .uci__dropdown--disabled>button{
  background-color: #ffffff !important;
}

.filter .uci__dropdown--disabled>button span,
.filter .disabled{
  color: #aeb5c1;
  cursor: not-allowed;
}

.search .uci__input div.uci__input__input-wrapper {
  background: #ffffff;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
}

.search .uci__input input::placeholder {
  color: #42526E;
  line-height: 24px;
}

.search .uci__input:hover:not(.uci__input--disabled) .uci__input__input-wrapper,
.search .uci__input--focus:not(.uci__input--disabled) .uci__input__input-wrapper {
  border-color: var(--c-primary-new) !important;
  background-color: #ffffff;
}

.search .hint-visible .uci__input div.uci__input__input-wrapper {
  border-color: var(--c-danger) !important;
}

.search .hint-visible .uci__input:hover:not(.uci__input--disabled) .uci__input__input-wrapper,
.search .hint-visible .uci__input--focus:not(.uci__input--disabled) .uci__input__input-wrapper {
  border-color: var(--c-danger) !important;
}

.search .uci__input>i .IconSearch_svg__uci__svg-icon, .search__input-error .uci__input>i .IconSearch_svg__uci__svg-icon{
  margin-top: 3px;
  max-height: 20px;
}

.search  .uci__input--disabled .uci__input__input-wrapper{
  background-color: #ffffff !important;
}

.search .uci__input--disabled input::placeholder,
.search .uci__input--disabled i{
  color: #aeb5c1;
}

.badges__container{
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
}

.log__selected {
  background: rgba(233, 233, 233, 0.2) !important;
  border-bottom: 0 !important;
  border-radius: 6px;
}

.log__selected>td {
  color: #7569F4 !important;
  font-weight: bold;
}

.log__detail>td{
  padding: 0 !important;
}

.filter__row {
  align-items: self-end;
}

.date-filter-wrapper .uci__tooltip {
  width: 100%;
  height: 100%;
}

.date-filter-wrapper .uci__tooltip div {
  width: 100%;
  height: 100%;
}
