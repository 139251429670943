.permission-error {
  height: 100%;
}

.permission-error .content-section {
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

.permission-error .error-icon {
  margin: auto auto 0;
  height: 200px;
  width: 200px;
}

.permission-error .error-message {
  margin: 24px auto auto;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
