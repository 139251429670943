.help{
  position: fixed;
  bottom: 16px;
  right: 30px;
  z-index: 5;
}

.help__button, .help__button:focus, .help__button:hover{
  width: 52px;
  height: 52px;
  background: #7569F4 !important;
  border-radius: 32px;
  border: 2px solid #7569F4 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  visibility: visible;
}

.tooltip__help{
  width: 190px;
  height: 51px;
  position: absolute;
  bottom: 58px;
  right: 15px;
  display: none;
}

.help:hover .tooltip__help{
  display: block;
}

.help__tooltip>div{
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px 6px 0 6px;
  padding: 0;
  width:100%;
}

.help__content__tooltip {
  background: #EAECFE;
  padding: 6px 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.help__header {
  background: #7569F4;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: flex-end;
  padding: 2px 12px 0 0;
}

.help__content {
  padding: 10px 15px;
}

.help__content p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #8B97AD;
  word-break: break-word;
}

.help__content__text {
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600 !important;
  color: #7569F4 !important;
}

.help__contact {
  padding: 6px 0;
  font-size: 14px;
  line-height: 19px;
  color: #8B97AD;
  word-break: break-all;
}

.help__contact a {
  font-weight: 400;
  color: #8B97AD;
}
