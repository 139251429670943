.error__message{
  color: #8B97AD;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 0 !important;
}

.error__info{
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #50586D;
}
