.group-modal .uci__modal>div>div {
  border-radius: 20px !important;
}

.group-modal .error_input .uci__fieldset,
.group-modal .error_input .uci__textarea .uci__textarea__input-wrapper {
  border: 2px solid hsl(12deg, 91%, 46%) !important;
}

.group-modal .error_input  legend {
  color: hsl(12deg, 91%, 46%) !important;
}
