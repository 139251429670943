.error button {
  box-shadow: 0 0 0 2px var(--c-primary--dark);
  color: var(--c-primary--dark) !important;
}

.error h1 {
  color: #42526e;
}
.error h3 {
  color: #42526e;
}
.error p {
  color: #42526e;
}

.error p a,
.error p a:visited {
  color: #556af6;
}
