.date__picker .date__picker__options__item {
  background: #ffffff;
  color: #42526e;
  display: block;
  line-height: 20px;
  border: none;
  font-weight: normal !important;
  font-family: Open Sans Regular;
  font-size: 16px;
  margin-bottom: 6px;
  padding: 10px 5px 10px 5px;
  cursor: pointer;
}

.date__picker .date__picker__card {
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: fixed;
  z-index: 5;
  padding: 20px;
}

.date__picker__tooltip>div{
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  padding: 0;
  width:100%;
}

.date__picker .date__picker__options__item:hover,
.date__picker .date__picker__options__item--active {
  background: #eaecfe;
  border-radius: 5px;
  transition: ease all 0.2s;
  color: #42526e;
  border: none;
}

.date__picker .date__picker__options__item:focus {
  font-family: Open Sans SemiBold;
  background: #eaecfe;
  border-radius: 5px;
  transition: ease all 0.2s;
  color: #42526e;
  border: none !important;
}

.date__picker__time {
  font-family: Open Sans SemiBold;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #42526e;
}

.date__picker__button__first-level {
  border-radius: 6px;
  background: #7569f4;
  color: #ffffff;
  padding: 5px 15px 5px 15px;
  line-height: 28px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.date__picker__button__second-level {
  font-weight: 600;
  border-radius: 6px;
  color: #292f41;
  padding: 5px 25px 5px 25px;
  line-height: 28px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
}

.date__picker__button__first-level:hover {
  background: #8a7eff;
}

.date__picker__button__first-level:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.date__picker__button__first-level:focus {
  background: #7569f4;
  border: 2px solid #4a1feb;
  box-sizing: border-box;
  border-radius: 6px;
}

.date__picker__button__second-level:hover {
  background: #eaecfe;
  border: 2px solid #7569f4;
  box-sizing: border-box;
  border-radius: 6px;
  color: #7569f4;
}

.date__picker__button__second-level:focus {
  background: #eaecfe;
  border: 2px solid #7569f4;
  box-sizing: border-box;
  border-radius: 6px;
  color: #7569f4;
}

.MuiGrid-item .uci__tooltip {
  width: 100%;
}

.calendar__input-row {
  width: 100%;
  height: 56px;
  .MuiButtonBase-root{
    height: 100%;
  }
}

.calendar__input-row .calendar__input-error {
  align-items: center;
}

.calendar__input-row .uci__input div.uci__input__input-wrapper {
  background: #ffffff;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}

.calendar__input-row .uci__label{
  margin: 2px;
}

.calendar__input-error .uci__label{
  margin: 2px;
}

.calendar__input-row .uci__input:hover:not(.uci__input--disabled) .uci__input__input-wrapper {
  border: 2px solid #7569f4;
  background-color: #ffffff;
}

.calendar__input-row .uci__input input::placeholder {
  line-height: 24px;
}

.calendar__input-row .uci__input--focus.uci__input--enabled:hover .uci__input__input-wrapper,
.uci__input--focus.uci__input--enabled .uci__input__input-wrapper {
  border-color: #7569f4 !important;
}

.calendar__input-error .uci__input--focus.uci__input--enabled .uci__input__input-wrapper,
.uci__input--blur .uci__input--enabled {
  border-color: var(--c-danger) !important;
}

.calendar__input-error .uci__input div.uci__input__input-wrapper {
  background: #ffffff;
}

.calendar__input-error .uci__input:hover:not(.uci__input--disabled) .uci__input__input-wrapper {
  border-color: var(--c-danger) !important;
  background-color: #ffffff;
}

.date__picker .uci__hint {
  max-width: fit-content;
}

/* Customize Calendar */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: "Open Sans Regular";
  line-height: 1.125em;
  border: none;
}

.react-calendar__month-view__weekdays {
  color: #6b778c;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 11px;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #172b4d;
}

.react-calendar__tile:enabled:hover {
  background: #eaecfe;
  border-radius: 6px;
}

.react-calendar__navigation button:enabled:hover {
  background: #eaecfe !important;
  border-radius: 6px;
}

.react-calendar__navigation button:enabled:focus {
  border-radius: 6px;
  background: #eaecfe;
  color: #7569f4;
}

.react-calendar__tile--active {
  background: #ffffff;
  color: #7569f4;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds {
  background: #eaecfe;
  border-radius: 6px;
}

.react-calendar__tile:disabled {
  color: #6b778c;
  cursor: not-allowed;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.calendar__input-row .btn-date-filter {
  color: #4A1FEB;
  background-color: #EAE6FF;
  padding: 5px 10px 5px 40px;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-align: left;
  position: relative;
  transition: ease all 0.2s;
}
.btn-date-filter{
  .css-59qrpi-MuiTypography-root{
    color: #4A1FEB;
  }
}

.calendar__input-row .btn-date-filter .uci__icon {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -13px;
}

.calendar__input-row .btn-date-filter:hover {
  cursor: pointer;
  background-color: rgba(234, 230, 255, .8);
}

.container-datepicker{
  .css-a6tdq7-MuiPaper-root-MuiCard-root{
    padding: 10px;
    width: auto;
  }
}
