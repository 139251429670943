.menu__dropdown__btn.css-1vebbep-MuiButtonBase-root-MuiButton-root {
  border: none;
}

.menu__dropdown__btn.css-1vebbep-MuiButtonBase-root-MuiButton-root:hover {
  border: none;
}

.menu__dropdown__btn{
  background-color: #ffffff !important;
  border-radius: 30px !important;
  min-height: 40px;
  justify-content: flex-start !important;
  padding: 2 !important;
  @media (min-width: 760px) and (max-width: 1323px){
    .MuiButton-endIcon{
      display: none;
    }
    .MuiTypography-H200{
      display: none;
    }
  }
}

.menu__dropdown__btn:disabled{
  pointer-events: unset !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.menu__dropdown__text{
  @media (min-width: 760px) and (max-width: 1323px){
    display: none
  }
}
