.site-selector-modal {
  padding: 10px 40px;
}

.site-selector-modal .error { 
  text-align: center;
}

.site-selector-modal .checked .site__name {
  font-weight: bold;
  font-size: 15px;
}

.site-selector-modal .uci__card-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #292F41;
}

.site-selector-modal .uci__card-footer .uci__button-group .uci__button--primary{
  background-color: #7569F4;
  border-color: #7569F4;
}

.site-selector-modal .site__active {
  color: #42526E;
  cursor: pointer;
  padding: 10px 16px 10px 12px;
  column-gap: 8px;
  border-radius: 30px;
}

.site-selector-modal .site__active:hover {
  background-color: var(--primary-light);
}

.site_selected {
  background-color: var(--primary-light);
}

.site-selector-modal .site__inactive {
  color: #8B97AD;
  padding: 10px 16px 10px 12px;
  column-gap: 8px;
  width: 466px;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  align-items: center;
}

.site-selector-modal .site__inactive:hover {
  background-color: var(--primary-light);
}

.site-selector-modal .site__name { 
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.site-selector-modal .site__inactive .site__locked__description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: none;
}

.site-selector-modal .site__inactive:hover .site__locked__description{
  display: block;
}

.site-selector-modal .site__inactive .locked__description {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.site-selector-modal .description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.site-selector-modal #lock__icon{
  margin: 6px 16px 2px 0;
  max-width: 16px;
}
  
.site-selector-modal #lock__icon:hover{
  transform: scale(1.25);
  cursor: pointer;
}

.site__column{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.site__icon-name-container{
  display: flex;
}
.site__name{
  margin-left: 8px;
}
