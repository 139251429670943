.apps{
  align-self: center;
  margin: 2px 0 2px 8px;
}

.apps a{
  text-decoration: none;
}

.app__active {
  color: #42526E;
  cursor: pointer;
  padding: 10px 8px;
  align-items: center;
  column-gap: 8px;
}

.app__inactive {
  color: #8B97AD;
  cursor: not-allowed;
  padding: 10px 8px;
  align-items: center;
  column-gap: 8px;
}

.app__active:hover, .app__inactive:hover {
  background: #EAECFE;
  border-radius: 5px;
  transition: ease all 0.2s;
}

.app__inactive:hover .description{
  display: none;
}

.app__inactive .locked__description{
  display: none;
}

.app__inactive:hover .locked__description{
  display: block;
}

.apps a .app__active .name, .apps .app__inactive .name {
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 21px;
}

.apps a .app__active .description, .apps .app__inactive .description, .apps .app__inactive .locked__description {
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
}

.app__inactive .name {
  display: flex;
  column-gap: 40px;
  align-items: center;
}

.app__badge {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 10px;
  line-height: 9px;
  color: #8B97AD;
}

#lock__icon{
  margin: 2px 0 2px 0;
  max-width: 16px;
}

#lock__icon:hover{
  transform: scale(1.25);
  cursor: pointer;
}
