.global-apps {
  grid-template-rows: 180px auto;
}

.global-apps__header{
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.global-apps__header .title{
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #383838;
}

.global-apps .uci__tab .uci__tab__body.uci__tab__header__body--active {
  min-height: 591px;
  display: flex;
  flex-direction: column;
}

.global-apps .uci__tab .uci__tab__header {
  border-bottom: none;
}

.global-apps .uci__tab .uci__tab__header button {
  border: none;
  background: transparent;
  color: #8B97AD;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.global-apps .uci__tab .uci__tab__header button:hover {
  color: #292F41;
}

.global-apps .uci__tab .uci__tab__header button.uci__tab__header__button--active {
  border-bottom: 2px solid #7569F4;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292F41;
}

.global-apps .uci__error-result {
  text-align: left;
  background-color: rgb(258,248,237);
  color: rgb(73,86,111);
  width: 90%;
  margin-top: auto;
}
.global-apps .uci__error-result h4{
  font-size:18px;
}
.global-apps .uci__error-result .uci__icon { 
  float: left;
  margin-right:10px;
} 
