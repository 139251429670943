.crash-init-error-card {
  background: #f5f5f5;
  border-radius: 15px;
  box-shadow: none;
  padding-block: inherit;
  margin: 33px 20px 28px 0;
  padding: 18px 25px 10px 26px;
}

.crash-init-error-description {
  color: #a5b2cb !important
}
