.custom-notification {
  display: flex;
  padding: 20px;
  min-width: 450px;
  gap: 15px;
}

.custom-notification .icon {
  display: flex;
  align-items: center;
}

.custom-notification .uci__spinner {
  position: relative !important;
  background: none !important;
}

.custom-notification .uci__spinner span {
  position: relative;
}

.custom-notification svg {
  width: 48px;
  height: 48px;
  color: #D9D9D9;
}

.custom-notification .message {
  width: 100%;
  display: flex;
  align-items: center;
}

.custom-notification h4 {
  font-family: 'Open Sans Semibold';
  font-size: 20px !important;
  line-height: 26px;
}

.custom-notification p {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  line-height: 20px;
  margin: 0 !important;
}

.custom-notification.default-style {
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}

.custom-notification.default-style h4 {
  color: #50586d;
}

.custom-notification.default-style p {
  color: #8B97AD;
}

.custom-notification.fixed-position {
  position: fixed !important;
  z-index: 1000;
  right: 90px;
  top: 100px;
}
