.application_mgmt-switchwrapper {
  margin-left: 5px;
}
.application_mgmt-navigationtype .uci__radio-button--vertical {
  margin-top: 10px;
}

.application_mgmt--right {
  float: right;
}

.application_mgmt--footer button {
  padding: 0 30px;
}

.application_mgmt--route {
  font-size: 16px;
  margin-top: 4px;
  font-family: "Open Sans SemiBold";
  color: #42526E;
}

.application_mgmt--dropdown .uci__dropdown:hover .uci__chips,
.application_mgmt--dropdown .uci__dropdown>button:hover {
  border: 2px solid var(--c-primary-new) !important;
  border-radius: 5px;
  background-color: #ffffff;
}

.application_mgmt--error .uci__dropdown .uci__chips,
.application_mgmt--error .uci__dropdown>button {
  border: 2px solid var(--c-danger) !important;
  border-radius: 5px;
  background-color: #ffffff;
}

.application_mgmt--icon-selector .uci__title.uci__title--tag-h4 {
  margin: 10px 0 10px 0 !important;
}

.application_mgmt--icon-selector .uci__icon {
  margin: 10px !important;
}

.application_mgmt--icon-selector--icon-selector .uci__icon--size-16>svg {
  position: relative !important;
  top: 5px !important;
}
.application_mgmt--icon-selector-modal .uci__icon {
  float: right !important;
  margin-right:10px;
}

.application_mgmt--icon-selector-modal a:hover{
  cursor: pointer;
}


.application_mgmt--icon-selector-modal .icon-list span{
  float: left;
}

.application_mgmt--icon-selector-modal .icon-list span:hover{
  cursor: pointer;
  color: var(--c-secondary) !important;
}
