.tenant-site-card.uci__card {
  padding: 7px 25px;
  min-height: 68px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  max-width: 550px;
}

.tenant-site-card p {
  margin: 0 0 5px 0;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  color: var(--c-background-dark-alt)
}

.tenant-site-card p strong {
  font-weight: 600;
}

.tenant-site-card p:last-child {
  margin-bottom: 0;
}

.tenant-site-card p.selected {
  color: var(--c-brand--opaque);
}

.tenant-site-card .open__button{
  max-width: 45px;
}

.tenant-site-card .vertically-centered {
  display: grid;
  align-items: center;
  min-height: 54px;
}

.tenant-site-card .uci__icon {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.tenant-site-card .uci__button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0 !important;
  text-align: center;
  max-width: unset;
  min-height: unset;
  background-color: #7569F4;
  border-color: #7569F4;
}

.tenant-site-card .open__button .uci__button:hover {
  background-color: var(--c-primary-new);
  border-color: var(--c-primary-new);
}

.tenant-site-card .uci__button i {
  left: 0 !important;
  top: 0 !important;
}

.tenant-site-card .uci__button .uci__icon {
  margin: 0;
  top: -1px;
  left: -1px;
}
