.uci__fieldset {
  border: 2px solid #DDDDDD;
  border-radius: 5px;
  width: 100%;
  padding: 0;
}

.uci__fieldset:hover, .uci__fieldset:focus-within {
  border: 2px solid var(--c-primary-new);
}

.error, .error:hover {
  border: 2px solid var(--c-danger) !important;
  border-radius: 5px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.uci__fieldset legend {
  color: #42526E;
  font-size: 14px;
  font-weight: 600;
  margin-left: 6px;
  padding: 0 4px;
}

.error legend {
  color: var(--c-danger);
  font-size: 14px;
  font-weight: 600;
  margin-left: 6px;
  padding: 0 4px;
}

.uci__fieldset .error .uci__hint {
  margin: 0 0 3px 5px;
}

.uci__fieldset input, .uci__fieldset input:focus {
  font-family: 'Open Sans Regular';
  border-radius: 3px;
  border-style: none;
  color: #091E42;
  background-color: transparent;
  padding: 8px 6px;
  font-size: 14px;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: 400;
}
