/* GRID */
.grid-template{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}

.grid-template__12-columns{
  grid-column: 1 / span 12;
}

/* RESPONSIVE */
.screen-md-view-menu__dropdown{
  @media (min-width: 760px) and (max-width: 1323px) {
    width: 10px !important;
    padding-right: 0 !important;
    .IconChevronDown_svg__uci__svg-icon{
      display: none;
    }
  }
}

/* LAYOUT */
.layout {
  height: 100vh;
  width: auto;
  grid-template-rows: 54px auto;
}

.layout__navbar {
  position: relative;
  background: var(--c-background-dark-alt);
  z-index: 2;
  height: 54px;
  width: 100%;
  transition: ease all 0.5s;
}

.layout__navbar:before  {
  content: '';
  position: absolute;
  height: 100%;
  left: -30px;
  right: -30px;
  z-index: -1;
  background: inherit;
}

.layout__main__wrapper {
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: calc(100vw - 0px);
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

.layout__main {
  width: calc(100% - 30px);
  height: 100%;
}

.layout__content {
  position: relative;
  z-index: 1;
  transition: ease all 500ms;
}

.layout__content__compactSidebar{
  animation: out__animation 400ms ease;
}

.layout__content__withSidebar{
  animation: in__animation 400ms ease;
}

@keyframes in__animation{
  0% {width: 115%; margin-left: -15%;}
  100% {width: 100%; margin-left: 0;}
}

@keyframes out__animation{
  0% {margin-left: 15%;}
  100% {margin-left: 0%;}
}

.layout__sidebar {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #DDDDDD;
  box-sizing: border-box;
  color: #ffffff;
  grid-template-rows: auto 54px;
  height: 100%;
  max-height: calc(100% - 56px);
  width: 5%;
  animation: out__animation__sidebar 400ms ease;
  display: flex;
  justify-content: center;
  padding: 10px 8px;
}

.layout__sidebar__full{
  width: 15%;
  animation: in__animation__sidebar 400ms ease;
  padding: 5px;
  padding: 10px 8px;
}

@keyframes in__animation__sidebar{
  0% {width:5% ;}
  100% {width:15% ;}
}

@keyframes out__animation__sidebar{
  0% {width:15% ;}
  100% {width:5% ;}
}

.layout__sidebar .layout__sidebar__menu__item {
  padding: 12px 8px 12px 10px;
  margin: 0 10px 0 0;
  position: relative;
  transition: ease all 0.2s;
  max-width: 40px;
}

.layout__sidebar__full .layout__sidebar__menu__item {
  max-width: 258px;
}

.layout__sidebar .layout__sidebar__menu__item:hover,
.layout__sidebar .layout__sidebar__menu__item--active {
  background: #EAECFE;
  border-radius: 25px;
  transition: ease all 0.2s;
}

.layout__sidebar .layout__sidebar__menu__item a {
  color: #42526E;
  outline: none !important;
  text-decoration: none !important;
  display: block;
  line-height: 20px;
}

.layout__sidebar .layout__sidebar__menu__item--disabled,
.layout__sidebar .layout__sidebar__menu__item--disabled:hover {
  cursor: not-allowed;
  color: #A0A0A0;
  display: flex;
  line-height: 20px;
  align-items: center;
}

.layout__sidebar__full .layout__sidebar__menu__item a {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
}

.layout__sidebar__full .layout__sidebar__menu__item--disabled,
.layout__sidebar__full .layout__sidebar__menu__item--disabled:hover {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
}

.layout__sidebar .layout__sidebar__menu__item>*,
.layout__sidebar .layout__sidebar__menu__item a>* {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout__sidebar .layout__sidebar__menu__item a button {
  visibility: hidden;
}

.layout__sidebar .layout__sidebar__menu__item a:hover button,
.layout__sidebar .layout__sidebar__menu__item--active a button {
  visibility: visible;
}

.layout__sidebar .layout__sidebar__menu__item a .uci__tooltip div,
.layout__sidebar .layout__sidebar__menu__item--disabled .uci__tooltip div {
  display: flex;
}

.layout__sidebar .layout__sidebar__menu__item a .uci__icon,
.layout__sidebar .layout__sidebar__menu__item--disabled .uci__icon{
  display: block;
  line-height: 20px;
}

.layout__sidebar__full .layout__sidebar__menu__item a span,
.layout__sidebar__full .layout__sidebar__menu__item--disabled span {
  display: inline-block;
  font-family: "Open Sans Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout__sidebar .layout__sidebar__menu__item a,
.layout__sidebar .layout__sidebar__menu__item--disabled div{
  max-height: 20px;
}

.layout__sidebar .layout__sidebar__menu__item:hover a span,
.layout__sidebar .layout__sidebar__menu__item--active a span {
  font-weight: bold;
}

.layout__sidebar .layout__sidebar__menu__item--divider {
  width: 100%;
  background-color: transparent;
  height: 1px;
  margin: 16px 0;
  cursor: inherit;
  padding: 0;
}

.layout__sidebar__full .layout__sidebar__menu__item--label,
.layout__sidebar .layout__sidebar__menu__item--labelMenu{
  font-family: "Open Sans Regular";
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #42526E;
  visibility: visible !important;
}

.layout__sidebar .layout__sidebar__menu__item--label{
  visibility: hidden;
  line-height: 16px;
  transition: none;  
}

.layout__sidebar .layout__sidebar__menu__item--labelMenu span,
.layout__sidebar .layout__sidebar__menu__item--label span{
  position: relative;
  right: 10px;
}

.layout__sidebar .layout__sidebar__menu__item--label:hover,
.layout__sidebar .layout__sidebar__menu__item--labelMenu:hover{
  background-color: transparent;
}

.layout-sidebar-controller button {
  cursor: pointer;
  border: none;
  margin: 12px 8px;
  padding: 0;
  outline: none !important;
}

.layout__submenu__button{
  background: transparent;
  color: #42526E;
  border: none;
  cursor: pointer;
  height: 100%;
}

/* Overlay Actions */

.layout.layout__ui-modal-overlay--show .layout__navbar {
  overflow: hidden;
}

.layout__ui-modal-overlay--hidden .layout-modal-overlay {
  pointer-events: none;
  opacity: 0;
  transition: opacity 220ms ease 0s;
  animation-duration: 0.5s;
}

.layout__ui-modal-overlay--show .layout-modal-overlay {
  opacity: 1;
  position: absolute;
  background: rgba(9, 30, 66, 0.54);
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  pointer-events: visible;
  transition: opacity 220ms ease 0s;
  animation-duration: 0.5s;
}

@media (max-width: 530px) {
  .layout.layout__ui-modal-overlay--show {
    grid-template-rows: 0 auto;
  }
}

.layout__sidebar__menu__item svg {
  width: 18px;
  height: 18px;
}
