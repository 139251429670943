.custom-app {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-app-wrapper {
  position: relative;
  width: calc(100% + 30px);
  height: 100%;
}
