.tenant-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.tenant__list__heading {
  color: var(--dark-gray-color);
}
.tenant__list .status-indicator {
  margin: 0 var(--gridSize);
}
.tenant--action--cell span {
  cursor: pointer;
}
