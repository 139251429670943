* {
  font-family: 'Open Sans Regular';
}

body, html {
  background-color: #f8f8fb!important;
  margin: 0;
}

:root {
  --gridSize: 8px;
  --dark-gray-color: #6B778C;
  --light-gray-color: #E1E1E1;

  --primary-main: rgba(117, 105, 244, 1);
  --primary-light: rgba(234, 236, 254, 1);

  --secondary-main: rgba(74, 31, 235, 1);
  --secondary-light: rgba(224, 225, 230, 1);
  --secondary-contrast: rgba(221, 221, 221, 1);

  --text-primary: rgba(41, 47, 65, 1);
  --text-secondary: rgba(95, 107, 129, 1);
  --text-disabled: rgba(165, 178, 203, 1);

  --success-main: rgba(34, 89, 0, 1);
  --success-light: rgba(180, 227, 150, 1);

  --warning-main: rgba(102, 85, 6, 1);
  --warning-light: rgba(255, 249, 190, 1);

  --error-main: rgba(151, 36, 36, 1);
  --error-light: rgba(254, 222, 222, 1);

  --info-main: rgba(117, 105, 244, 1);
  --info-light: rgba(234, 230, 255, 1);
}

.app {
  height: 100vh;
  width: auto !important;
  grid-template-rows: 54px auto;
}

.app__body {
  position: relative;
  z-index: 1;
  height: calc(100vh - 54px);
}

.app__header {
  position: relative;
  z-index: 3;
  height: 54px;
  background: var(--c-background-dark-alt);
}

.app__header:before  {
  content: '';
  position: absolute;
  height: 100%;
  left: -30px;
  right: -30px;
  z-index: -1;
  background: inherit;
}

button {
  background-color: transparent;
}

.uci__button__icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

hr {
  background-color: #DFE1E6;
  height: 2px;
  border: none;
}

@media (max-width: 575px) {
  .content-wrapper .content-section {
    margin: 16px!important;
  }
}

.uci__badge__container>* {
  margin-right: 16px;
}

@media (min-width: 481px) {
  #modal-add-custom-permission .uci__card-body {
    overflow: visible!important;
  }
  #modal-add-tenant-into-role .uci__card-body {
    overflow: visible!important;
  }
}

.uci__hover:hover {
  background-color: #e4e6ea;
}

.jarvis__actions-cols {
  text-align: right !important;
}
