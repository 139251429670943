.jarvis-table-manage-site table {
  min-width: 600px;
}

.jarvis-table-manage-site tr td:first-child{
  width: 160px;
}

.jarvis-table-manage-site tr td:last-child{
  width: 30px;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1;
}
